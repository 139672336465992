import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./auth/login/login.component').then((c) => c.AppLoginComponent),
  },
  {
    path: 'demo',
    loadComponent: () =>
      import(
        '../../projects/demo-lib/src/lib/components/demo-lib.component'
      ).then((c) => c.DemoLibComponent),
  },
];
